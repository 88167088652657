import { NextPageWithLayout } from "@bptypes/layout";
import { FacetFilter } from "@components/filters";
import {
	ALLOWED_VIEW_TYPES,
	HeadingWithBreadcrumb,
} from "@components/headings/HeadingWithBreadcrumb";
import { ReleasesList } from "@components/lists";
import { Pager, urlParamsToResetPagination } from "@components/paging";
import { PER_PAGE_OPTIONS } from "@components/paging/Pager/Pager";
import { ReleasesTable } from "@components/tables/ReleasesTable";
import MainLayout from "@layouts/MainLayout";
import { DEFAULT_LOCALE } from "@lib/constants";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { getLabelQuery } from "@lib/network/labels";
import { getReleasesQuery } from "@lib/network/releases";
import { getSafeDefaultPageParam, getSafeDefaultPerPageParam } from "@lib/utils";
import { getLocationData } from "@lib/utils/getLocationData";
import { Label } from "@models/label";
import { device } from "@styles/theme";
import { DehydratedState, QueryClient, dehydrate, useQuery } from "@tanstack/react-query";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { i18n } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";

interface Props {
	label?: Label;
	anonSession: AnonSession;
}

const LabelReleases: NextPageWithLayout<Props> = ({ label, anonSession }) => {
	const router = useRouter();
	const isDesktop = useMediaQuery({ query: device.xl });
	const { getAccessToken, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();
	const {
		page,
		per_page,
		order_by,
		preorders,
		sub_genre_id,
		publish_date,
		artist_id,
		genre_id,
	} = router.query;

	const [queryParams, setQueryParams] = useState({
		page: getSafeDefaultPageParam(page),
		per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
		order_by: order_by ? order_by.toString() : "-release_date,id",
		genre_id: genre_id ? genre_id.toString() : "",
		include_facets: true,
		preorder: preorders ? preorders === "true" : false,
		publish_date: publish_date ? publish_date.toString() : "",
		sub_genre_id: sub_genre_id ? sub_genre_id.toString() : "",
		artist_id: artist_id ? artist_id.toString() : "",
		label_id: label?.id.toString() || "",
	});

	useEffect(() => {
		setQueryParams({
			...queryParams,
			page: getSafeDefaultPageParam(page),
			per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
			preorder: preorders ? preorders === "true" : false,
		});
	}, [page, per_page, preorders]);

	const releasesQuery = useQuery(getReleasesQuery({ params: queryParams, accessToken }));

	const handleFacetFilterChange = (data: Record<string, string>) => {
		router.replace(
			{
				query: {
					...router.query,
					...data,
				},
			},
			undefined,
			{ shallow: true },
		);
		setQueryParams({
			...queryParams,
			...data,
			...urlParamsToResetPagination(router),
		});
	};

	const handleFacetFilterReset = () => {
		const path = router.asPath.split("?")[0];
		router.push(path);

		setQueryParams({
			page: 1,
			per_page: 25,
			order_by: "-release_date,id",
			genre_id: "",
			include_facets: true,
			preorder: false,
			publish_date: "",
			sub_genre_id: "",
			artist_id: "",
			label_id: label?.id.toString() || "",
		});
	};

	return (
		<>
			<HeadingWithBreadcrumb
				location={`Label - ${label?.name} - Releases`}
				id={label?.id || 0}
				slug={label?.slug || ""}
				viewType={ALLOWED_VIEW_TYPES.LABEL}
				title={label?.name || ""}
				showFollow
			/>
			{queryParams.include_facets && (
				<FacetFilter
					facets={releasesQuery.data?.facets?.fields}
					hide={["label"]}
					onChange={handleFacetFilterChange}
					onReset={handleFacetFilterReset}
					defaults={queryParams}
					showReleaseFilter
				/>
			)}

			{isDesktop && (
				<Pager
					totalResults={releasesQuery.data?.count}
					showPreordersFilter
				/>
			)}

			{isDesktop ?
					(
						<ReleasesTable
							location={`Label Page - ${label?.name} - Releases Tab`}
							showNumbers={false}
							isLoading={releasesQuery.isLoading}
							releases={releasesQuery.data ? releasesQuery.data.results : []}
						/>
					) :
					(
						<ReleasesList
							location={`Label Page - ${label?.name} - Releases Tab`}
							showNumbers={false}
							releases={releasesQuery.data ? releasesQuery.data.results : []}
						/>
					)}

			<Pager

				totalResults={releasesQuery.data?.count}
				showPreordersFilter
			/>
		</>
	);
};

export default LabelReleases;

LabelReleases.getLayout = (page: ReactElement<Props>) => {
	const labelImage: string = (page.props.label?.image.uri || "");
	return (
		<MainLayout
			title={i18n?.t("Label.ArtistMusicDownload", { label: page.props.label?.name })}
			metaDescription={
				page.props.label?.bio ?
					page.props.label?.bio :
					i18n?.t("Explore", { name: page.props.label?.name })
			}
			hasDesktopSidebar={true}
			socialImage={labelImage}
		>
			{page}
		</MainLayout>
	);
};

type ServerSideProps = {
	dehydratedState: DehydratedState;
	anonSession: AnonSession;
	label: Label;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
	ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<ServerSideProps>> => {
	const queryClient = new QueryClient();

	const {
		page,
		per_page,
		order_by,
		preorders,
		sub_genre_id,
		publish_date,
		artist_id,
		genre_id,
	} = ctx.query;

	const location = getLocationData(ctx.req);
	const { id } = ctx.params as { id: string };
	const labelId = id ? parseInt(id as string, 10) : 0;
	let label: Label | null = null;

	if (labelId > 0) {
		const data = await Promise.all([
			queryClient
				.fetchQuery<Label>(getLabelQuery({ labelId, location }))
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getReleasesQuery({
						params: {
							page: getSafeDefaultPageParam(page),
							per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
							order_by: order_by ? order_by.toString() : "-release_date,id",
							genre_id: genre_id ? genre_id.toString() : "",
							include_facets: true,
							preorder: preorders ? preorders === "true" : false,
							publish_date: publish_date ? publish_date.toString() : "",
							sub_genre_id: sub_genre_id ? sub_genre_id.toString() : "",
							artist_id: artist_id ? artist_id.toString() : "",
							label_id: labelId.toString() || "",
						},
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
		]);

		label = data[0] as Label;
	}

	const anonSession = await exportAnonSession();

	if (!label) {
		return {
			notFound: !label,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			anonSession,
			...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ["translation"])),
			label: label,
		},
	};
};
